import React from 'react'

const Page1 = ({
	formData,
	setFormData,
	setCurrentPage,
}) => {

	const handleContinue = () => {
		if(formData.businessType !== 'default') {
			setCurrentPage(2)
		}
	}

	return (
		<div className='h-full flex flex-col items-center justify-center'>
			<div className='flex flex-col items-center text-center'>
				<p className='text-[25px] md:text-[30px] lg:text-[30px] text-themeColor font-extrabold'>Welcome to Anderson:Kent Financial's Online Application</p>
				<p className='mt-4 text-[20px] md:text-[30px] lg:text-[30px] text-themeColor font-medium'>Before we begin, please answer the four following pre-qualification questions:</p>
			</div>
			<div className='mt-[25px] flex flex-col'>
				<p className='text-[18px] md:text-[28px] lg:text-[28px] font-semibold text-center max-w-[750px]'>1. What type of business do you own?</p>

				<select className="mt-[15px] text-[14px] md:text-[18px] lg:text-[18px] bg-[#f1f1f1] cursor-pointer" value={formData.businessType} onChange={(e) => setFormData({...formData, businessType: e.target.value})}>
					<option value="default">Select a Business Type</option>
					<option value="ATV/Golf Cart Dealer">ATV/Golf Cart Dealer</option>
					<option value="Accountant">Accountant</option>
					<option value="Acute Care Facility">Acute Care Facility</option>
					<option value="Advertising/Public Relations">Advertising/Public Relations</option>
					<option value="Aerospace">Aerospace</option>
					<option value="Agricultural Services">Agricultural Services</option>
					<option value="Alternative Energy Production &amp; Services">Alternative Energy Production &amp; Services</option>
					<option value="Architect">Architect</option>
					<option value="Architectural Services">Architectural Services</option>
					<option value="Attorney or Law Firm">Attorney or Law Firm</option>
					<option value="Auditory Center">Auditory Center</option>
					<option value="Automotive Parts Store">Automotive Parts Store</option>
					<option value="Automotive Repair">Automotive Repair</option>
					<option value="Automotive Services">Automotive Services</option>
					<option value="Bakery">Bakery</option>
					<option value="Bar/Tavern">Bar/Tavern</option>
					<option value="Barber Shop">Barber Shop</option>
					<option value="Beauty Salon">Beauty Salon</option>
					<option value="Bed &amp; Breakfast Inn">Bed &amp; Breakfast Inn</option>
					<option value="Bicycle Shop">Bicycle Shop</option>
					<option value="Book Store">Book Store</option>
					<option value="Bottling &amp; Distribution">Bottling &amp; Distribution</option>
					<option value="Bowling Alley">Bowling Alley</option>
					<option value="Brewery">Brewery</option>
					<option value="Builders/Residential">Builders/Residential</option>
					<option value="Building Equipment">Building Equipment</option>
					<option value="Building Materials">Building Materials</option>
					<option value="Business Services">Business Services</option>
					<option value="Cable &amp; Satellite TV Production &amp; Distribution">Cable &amp; Satellite TV Production &amp; Distribution</option>
					<option value="Café in Fitness Center">Café in Fitness Center</option>
					<option value="Cancer Radiology Center">Cancer Radiology Center</option>
					<option value="Cancer Treatment Facility">Cancer Treatment Facility</option>
					<option value="Candy Store">Candy Store</option>
					<option value="Car Wash">Car Wash</option>
					<option value="Caterer">Caterer</option>
					<option value="Cheese Store">Cheese Store</option>
					<option value="Chemical &amp; Related Manufacturing">Chemical &amp; Related Manufacturing</option>
					<option value="Chiropractor">Chiropractor</option>
					<option value="Cigar Store">Cigar Store</option>
					<option value="Clinic">Clinic</option>
					<option value="Clothing Manufacturing">Clothing Manufacturing</option>
					<option value="Coffee Shop">Coffee Shop</option>
					<option value="Commercial Cleaning Services">Commercial Cleaning Services</option>
					<option value="Commercial Inspection Services">Commercial Inspection Services</option>
					<option value="Commercial Landscaping &amp; Design">Commercial Landscaping &amp; Design</option>
					<option value="Communication Company">Communication Company</option>
					<option value="Computer Software">Computer Software</option>
					<option value="Construction">Construction</option>
					<option value="Construction Services / Sub-Contractors">Construction Services / Sub-Contractors</option>
					<option value="Convenience Food Stores">Convenience Food Stores</option>
					<option value="Cosmetic/Plastic Surgery">Cosmetic/Plastic Surgery</option>
					<option value="Dairy">Dairy</option>
					<option value="Defense Contractor">Defense Contractor</option>
					<option value="Defense Electronics">Defense Electronics</option>
					<option value="Dentist">Dentist</option>
					<option value="Diagnostic Imaging Center">Diagnostic Imaging Center</option>
					<option value="Distillery">Distillery</option>
					<option value="Doctor">Doctor</option>
					<option value="Drug Manufacturing">Drug Manufacturing</option>
					<option value="Dry Cleaner">Dry Cleaner</option>
					<option value="E*Commerce">E*Commerce</option>
					<option value="Electronic Store">Electronic Store</option>
					<option value="Electronics Manufacturing">Electronics Manufacturing</option>
					<option value="Energy &amp; Natural Resources">Energy &amp; Natural Resources</option>
					<option value="Engineer">Engineer</option>
					<option value="Event Planner">Event Planner</option>
					<option value="Florist">Florist</option>
					<option value="Food Gift Basket Store">Food Gift Basket Store</option>
					<option value="Food Manufacturing">Food Manufacturing</option>
					<option value="Food Processing &amp; Sales">Food Processing &amp; Sales</option>
					<option value="For-profit Education">For-profit Education</option>
					<option value="Gas &amp; Oil">Gas &amp; Oil</option>
					<option value="General Contractors">General Contractors</option>
					<option value="Gift Store">Gift Store</option>
					<option value="Gourmet Food Store">Gourmet Food Store</option>
					<option value="Gourmet Popcorn Store">Gourmet Popcorn Store</option>
					<option value="Grocery Store">Grocery Store</option>
					<option value="Gym / Fitness">Gym / Fitness</option>
					<option value="Ham Store">Ham Store</option>
					<option value="Hardware Store">Hardware Store</option>
					<option value="Health Professional">Health Professional</option>
					<option value="Home Healthcare">Home Healthcare</option>
					<option value="Hospital">Hospital</option>
					<option value="Hotel">Hotel</option>
					<option value="IT Hardware Sales">IT Hardware Sales</option>
					<option value="IT Services">IT Services</option>
					<option value="Ice Cream/Yogurt Store">Ice Cream/Yogurt Store</option>
					<option value="Jewelry Stores">Jewelry Stores</option>
					<option value="Legal Services">Legal Services</option>
					<option value="Liquor Store">Liquor Store</option>
					<option value="Locksmith &amp; Security">Locksmith &amp; Security</option>
					<option value="Logging, Timber &amp; Paper Mills">Logging, Timber &amp; Paper Mills</option>
					<option value="Logistics">Logistics</option>
					<option value="Mail &amp; Package Store">Mail &amp; Package Store</option>
					<option value="Mall Food Outlet">Mall Food Outlet</option>
					<option value="Manufacturing">Manufacturing</option>
					<option value="Meat processing &amp; products">Meat processing &amp; products</option>
					<option value="Medical Equipment">Medical Equipment</option>
					<option value="Medical Services">Medical Services</option>
					<option value="Medical Spa">Medical Spa</option>
					<option value="Medical Supplies">Medical Supplies</option>
					<option value="Motel">Motel</option>
					<option value="Movie Theatre/Playhouse">Movie Theatre/Playhouse</option>
					<option value="Nail Salon">Nail Salon</option>
					<option value="Natural Gas Pipelines">Natural Gas Pipelines</option>
					<option value="Newspaper, Magazine &amp; Book Publishing">Newspaper, Magazine &amp; Book Publishing</option>
					<option value="Nightclub">Nightclub</option>
					<option value="Nursing Home">Nursing Home</option>
					<option value="Nutritional &amp; Dietary Supplies">Nutritional &amp; Dietary Supplies</option>
					<option value="Optical/Vision Store">Optical/Vision Store</option>
					<option value="Orthodontist">Orthodontist</option>
					<option value="Pet Grooming/Boarding">Pet Grooming/Boarding</option>
					<option value="Pet Store">Pet Store</option>
					<option value="Pharmaceuticals &amp; Health Products">Pharmaceuticals &amp; Health Products</option>
					<option value="Pharmacy">Pharmacy</option>
					<option value="Photo Studio">Photo Studio</option>
					<option value="Physical Rehabilitation">Physical Rehabilitation</option>
					<option value="Podcast Producer">Podcast Producer</option>
					<option value="Prepackaged Meals">Prepackaged Meals</option>
					<option value="Printing &amp; Publishing">Printing &amp; Publishing</option>
					<option value="Professional Sports Arena">Professional Sports Arena</option>
					<option value="Professional Sports Equipment &amp; Services">Professional Sports Equipment &amp; Services</option>
					<option value="Quick Copy/Printing">Quick Copy/Printing</option>
					<option value="Radio Station/Services">Radio Station/Services</option>
					<option value="Restaurant">Restaurant</option>
					<option value="Retail Store - Other">Retail Store - Other</option>
					<option value="Smoothie Shop">Smoothie Shop</option>
					<option value="Spa">Spa</option>
					<option value="Sporting Goods Store">Sporting Goods Store</option>
					<option value="Steel Fabricator">Steel Fabricator</option>
					<option value="Steel Production">Steel Production</option>
					<option value="TV Station/Services">TV Station/Services</option>
					<option value="Telecom Services &amp; Equipment">Telecom Services &amp; Equipment</option>
					<option value="Textiles">Textiles</option>
					<option value="Tractor &amp; Lawn Mower Sales">Tractor &amp; Lawn Mower Sales</option>
					<option value="Trash Collection &amp; Waste Management">Trash Collection &amp; Waste Management</option>
					<option value="Trucking">Trucking</option>
					<option value="Urgent Care Facility">Urgent Care Facility</option>
					<option value="Veterinarian">Veterinarian</option>
					<option value="Wholesale">Wholesale</option>
					<option value="Winery">Winery</option>
				</select>

				<button className='ml-auto mr-auto mt-8 styled-button pl-[10px] pr-[10px]' onClick={handleContinue}>continue</button>
			</div>
		</div>
	)
}

export default Page1