import React from 'react'

const Footer = () => {
	return (
		<div className="fixed bottom-0 flex flex-col items-center justify-center w-full h-[100px] bg-themeColor text-white">
			<p className='pl-4 pr-4 text-sm md:text-xl lg:text-xl font-bold text-center'>Since 2001, we’ve helped over 18,000 business owners secure proceeds of more than $1 billion.</p>
			<p className='text-[10px] md:text-sm lg:text-sm mt-2'>Copyright {new Date().getFullYear()} Anderson:Kent Financial Services - All Rights Reserved</p>
		</div>
	)
}

export default Footer