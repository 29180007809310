import React from 'react'

const ThankYouPage = () => {

	const handleContinue = () => {
		window.location = "https://beta.akfsi.com/registration"
	}

	return (
		<div className='mt-[60px] h-full flex flex-col items-center justify-center'>
			<div className='mt-[25px] flex flex-col'>
				<p className='text-[18px] md:text-[24px] lg:text-[24px] font-semibold text-center max-w-[750px]'>Thank you! Please press CONTINUE to begin the application.</p>
				<button className='ml-auto mr-auto mt-8 styled-button pl-6 pr-6' onClick={handleContinue}>continue</button>
			</div>
		</div>
	)
}

export default ThankYouPage