import React, { useState } from 'react'
import Page1 from '../../pages/Page1'
import Page2 from '../../pages/Page2'
import Page3 from '../../pages/Page3'
import Page4 from '../../pages/Page4'
import ThankYouPage from '../../pages/ThankYouPage'
import MinimumRequirementsPage from '../../pages/MinimumRequirementsPage'

const Form = () => {

	const [formData, setFormData] = useState({
		businessType: 'default',
		isHomeBasedBusiness: '',
		doesBusinessGenerateMinimum: '',
		businessUnderSameOwnershipForLast30Days: '',
	})

	const [currentPage, setCurrentPage] = useState(1)
	const [showThankYouPage, setShowThankYouPage] = useState(false)
	const [minimumRequirementsPage, setMinimumRequirementsPage] = useState(false)

	const conditions = [
		{ check: formData.isHomeBasedBusiness === "YES", reason: "Your business is home-based." },
		{ check: formData.doesBusinessGenerateMinimum === "NO", reason: "Your annual sales are below $200,000." },
		{ check: formData.businessUnderSameOwnershipForLast30Days === "NO", reason: "Your business has not been open and operating under the same ownership for 30 days or longer." }
	]

	const handleSubmitForm = () => {
		if(formData.businessType) {
			
			const reasons = conditions.filter(condition => condition.check).map(condition => `- ${condition.reason}`)

			console.log(formData);
			
			if (reasons.length > 0) {
				setMinimumRequirementsPage(true)
			} else {
				setShowThankYouPage(true)
			}
		}
	}

	const getCurrentFormPage = () => {
		switch(currentPage) {
			case 1:
				return <Page1
					formData={formData}
					setFormData={setFormData}
					setCurrentPage={setCurrentPage}
				/>
			case 2:
				return <Page2
				formData={formData}
				setFormData={setFormData}
				setCurrentPage={setCurrentPage}
			/>
			case 3:
				return <Page3
				formData={formData}
				setFormData={setFormData}
				setCurrentPage={setCurrentPage}
			/>
			case 4:
				return <Page4
				formData={formData}
				setFormData={setFormData}
				handleSubmitForm={handleSubmitForm}
			/>
			default:
				return <Page1
					formData={formData}
					setFormData={setFormData}
					setCurrentPage={setCurrentPage}
				/>
		}
	}

	return (
		<div className='mt-[220px] mb-[150px] md:mt-[160px] md:mb-[160px] lg:mt-[160px] lg:mb-[100px] h-full w-full pl-[25px] pr-[25px] pt-[50px] pb-[50px]'>
			{
				!minimumRequirementsPage
				?
				!showThankYouPage
				?
				getCurrentFormPage()
				:
				<ThankYouPage />
				:
				<MinimumRequirementsPage formData={formData}/>
			}
		</div>
	)
}

export default Form