import React from 'react'

const MinimumRequirementsPage = ({
	formData,
}) => {

	const conditions = [
		{ condition: formData.isHomeBasedBusiness === "YES", message: "Your business is home-based." },
		{ condition: formData.doesBusinessGenerateMinimum === "NO", message: "Your annual sales are below $200,000." },
		{ condition: formData.businessUnderSameOwnershipForLast30Days === "NO", message: "Your business has not been open and operating under the same ownership for 30 days or longer." },
	]

	const getReasons = () => {
		const reasons = []
		conditions.forEach(({ condition, message }) => {
			if (condition) {
				reasons.push(`- ${message}`)
			}
		})
		return reasons
	}

	return (
		<div className='h-full flex flex-col items-center justify-center'>
			<p className='text-[18px] md:text-[28px] lg:text-[28px] font-semibold'>Based on your responses, we are unable to assist you at this time for the following reason(s):</p>

			<div className='mt-[50px] flex flex-col  max-w-[650px] text-center text-[18px] md:text-[22px] lg:text-[22px] font-extrabold text-themeColorSecondary'>
				{
					getReasons().map((reason) => (
						<p>{reason}</p>
					))
				}
			</div>

			<p className='mt-[25px] text-[18px] md:text-[28px] lg:text-[28px] text-center font-extrabold'>Our Minimum Requirements To Apply Are:</p>

			<div className='flex flex-col justify-center w-full md:flex-row lg:flex-row mt-[40px]'>
				<p className='requirement-option text-[#337ab7] hover:text-themeColor underline'>Approved Business Types</p>
				<div className='requirement-option text-themeColor underline'>Minimum $200,000 <br /> Annual Sales</div>
				<div className='requirement-option text-themeColor underline'>Minimum 30 Days <br />Time In Business</div>
			</div>

			<p className='mt-4 font-bold'>You may close this window.</p>
		</div>
	)
}

export default MinimumRequirementsPage