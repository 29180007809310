import React from 'react'

const Page3 = ({
	formData,
	setFormData,
	setCurrentPage,
}) => {

	const handleYes = () => {
		setFormData({...formData, doesBusinessGenerateMinimum: 'YES'})
		setCurrentPage(4)
	}
	
	const handleNo = () => {
		setFormData({...formData, doesBusinessGenerateMinimum: 'NO'})
		setCurrentPage(4)
	}

	return (
		<div className='h-full flex flex-col items-center justify-center'>
			<div className='flex flex-col items-center text-center'>
				<p className='text-[20px] md:text-[30px] lg:text-[30px] text-themeColor font-extrabold'>Application Pre-qualification Questions</p>
			</div>
			<div className='mt-[25px] flex flex-col'>
				<p className='text-[18px] md:text-[28px] lg:text-[28px] font-semibold text-center max-w-[750px]'>3. Does your business generate at least $200,000 of gross sales annually?</p>

				<div className='mt-[30px] ml-auto mr-auto flex justify-between w-[200px]'>
					<button className='ml-auto mr-auto mt-8 styled-button pl-6 pr-6' onClick={handleYes}>yes</button>
					<button className='ml-auto mr-auto mt-8 styled-button pl-6 pr-6' onClick={handleNo}>no</button>
				</div>
			</div>
		</div>
	)
}

export default Page3