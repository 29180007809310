import React, { useEffect, useState } from 'react'

const Page4 = ({
	formData,
	setFormData,
	handleSubmitForm,
}) => {

	const [formCompleted, setFormCompleted] = useState(false)

	useEffect(() => {
		if(formCompleted) {
			handleSubmitForm()
		}
		// eslint-disable-next-line
	}, [formData])

	const handleYes = () => {
		setFormData({...formData, businessUnderSameOwnershipForLast30Days: 'YES'})
		setFormCompleted(true)
	}
	
	const handleNo = () => {
		setFormData({...formData, businessUnderSameOwnershipForLast30Days: 'NO'})
		setFormCompleted(true)
	}

	return (
		<div className='h-full flex flex-col items-center justify-center'>
			<div className='flex flex-col items-center text-center'>
				<p className='text-[20px] md:text-[30px] lg:text-[30px] text-themeColor font-extrabold'>Application Pre-qualification Questions</p>
			</div>
			<div className='mt-[25px] flex flex-col'>
				<p className='text-[18px] md:text-[28px] lg:text-[28px] font-semibold text-center max-w-[750px]'>4. Has your business been open and operating under the same ownership for 30 days or longer?</p>

				<div className='mt-[10px] ml-auto mr-auto flex justify-between w-[200px]'>
					<button className='ml-auto mr-auto mt-8 styled-button pl-6 pr-6' onClick={handleYes}>yes</button>
					<button className='ml-auto mr-auto mt-8 styled-button pl-6 pr-6' onClick={handleNo}>no</button>
				</div>
			</div>
		</div>
	)
}

export default Page4