import Logo from '../../assets/akfsi-logo.png'
import EmailUsIcon from '../../assets/emailus-icon.png'

const Header = () => {
	return (
		<div className="fixed top-0 flex pt-[30px] pb-[20px] min-h-[112px] w-full bg-themeColor">
			<div className="flex flex-col md:flex-row lg:flex-row flex-[1]">
				<div className="flex flex-[0.5] items-center justify-center">
					<img className="h-[80px]" src={Logo} alt="logo" />
				</div>
				<div className='w-[90%] h-[1px] bg-white ml-auto mr-auto mb-2 mt-2 md:hidden lg:hidden' />
				<div className="flex flex-[0.5] justify-center items-center">
					<a href='mailto:test@test.com' className='flex flex-col items-center hover:underline text-white hover:text-themeColorSecondary'>
						<img src={EmailUsIcon} alt="email us icon" />
						<p>Email Us</p>
					</a>
					<div className='ml-6 flex flex-col items-end'>
						<p className='text-white'>Contact Us</p>
						<a className='text-white hover:text-themeColorSecondary text-[15px] md:text-[30px] lg:text-[30px] font-bold' href='tel:0000000000'>000.000.0000</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header