import Footer from "./components/Footer";
import Form from "./components/Form";
import Header from "./components/Header";

const App = () => {
  return (
    <div className="app w-full flex flex-col">
      <Header />
      <Form />
      <Footer />
    </div>
  );
}

export default App;